@use 'styles/globals/placeholders' as *;


.block{
    @extend %options_block;
}

.step{
    background-color: var(--ocean) !important;
}

