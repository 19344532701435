@use 'styles/globals/placeholders' as *;
@use 'styles/globals/breakpoints';


.popover{
    @extend %popover;
    max-width: 500px;
    border: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &::backdrop {
        @extend %modal-underlay;
    }

    @media screen and (max-width: breakpoints.$screen-sm){
        width: 90%;
    }
}

.statusModal{
    padding: 3rem !important;
    max-width: 600px;
}

.closeBtn{
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
}
 
.arrow{
    @extend %popover_arrow;
}

.heading{
    @extend %h4;
    font-size: 1.8rem;
    margin-top: 2rem;
}

.logos{
    justify-content: flex-start !important;
}

