@use 'styles/globals/placeholders' as *;
@use 'styles/globals/breakpoints';

.block{
    position: relative;
    display: block;
}

.list{
    display: flex;
    list-style: none;
    padding: 1rem 0 2rem 0;    

    @media screen and (max-width: breakpoints.$screen-sm){
        display: block;
        columns: 2;
    }
}

.helpText{
    padding-top: 0.5rem;
    font-size: 1.3rem;
    text-transform: none;
    font-weight: normal;
    letter-spacing: normal;
    margin-bottom: 1rem;
    display: block;
}

.icon{
    fill: var(--white);
}

.learnMore{
    line-height: 1;
    background-color: transparent;
    border-bottom: 1px solid var(--link_color);
    color: var(--link_color);
    font-weight: 600;
}

.header{
    display: flex;
    flex-direction: column;
}

.heading{
    display: flex;
    align-items: baseline;
    @extend %h6;
    font-size: 1.8rem;
    margin-bottom: 0;
    line-height: 1;

    & .logo{
        margin-left: 0.5rem;
    }
}

.headingText{
    font-size: 1.6rem;
}

.atcPlanLoading{
    filter: blur(3px);
}

.loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.undo{
    padding: 0
}

.declined{
    border: 1px solid var(--ice);
    justify-content: space-between !important;
}

